/* eslint-disable prettier/prettier */
import { Box, Card, Typography } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
// components
import Page from '../components/Page';
// layouts
import AuthLayout from '../layouts/AuthLayout';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  height: '100%',
  borderRadius: 0
}));

const SectionStyle = styled(Card)(() => ({
  width: '95%',
  maxWidth: 520,
  height: '63%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: '0 10vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const BoxStyle = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.between('xs', 'md')]: {
    padding: 15
  }
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | SistemaNube">
      <AuthLayout />

      <MHidden width="xsDown">
        <SectionStyle>
          <ContentStyle>
            <BoxStyle sx={{ mb: 5 }}>
              <Typography variant="h3" gutterBottom>
                Fidelizá a tus clientes
              </Typography>
            </BoxStyle>

            <LoginForm />
          </ContentStyle>
        </SectionStyle>
      </MHidden>
    </RootStyle>
  );
}
