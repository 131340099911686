import axios from 'axios';
import { useFormik } from 'formik';
import { loginSchema } from './login.schema';
import { handleApiError } from '../utils';

export const useLoginFormik = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      clubId: ''
    },
    validationSchema: loginSchema,
    onSubmit: (values, formikHelpers) => {
      const { setSubmitting } = formikHelpers;
      const { email, password, clubId } = values;

      const data = {
        email,
        password,
        appId: 'cloud-system-panel',
        clubId
      };

      setSubmitting(true);

      axios
        .post(`${process.env.REACT_APP_BASE_URL_API}/v1/clubs/auth/login`, data, {
          headers: {
            'x-transaction-origin': 'cloud-system-login'
          }
        })
        .then((res) => {
          setSubmitting(false);
          const urlPanel = process.env.REACT_APP_BASE_URL_PANEL.replace(
            '{subdomain}',
            res.data.club_subdomain
          );

          window.location.href = `${urlPanel}/login?token=${res.data.access_token}`;
        })
        .catch((err) => {
          console.log('err', err);
          handleApiError(err);
          setSubmitting(false);
        });
    }
  });

  return formik;
};
