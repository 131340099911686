import swal from 'sweetalert';

export const showGeneralError = (errorMessage) =>
  swal({
    title: errorMessage ? undefined : 'Ups...',
    text: errorMessage || 'Ha ocurrido un error',
    icon: 'error',
    buttons: [false, 'OK']
  });

export const showGeneralSuccess = (message) =>
  swal({
    title: message ? undefined : 'OK !',
    text: message || 'Se guardó correctamente',
    icon: 'success',
    buttons: ['Cerrar', false]
  });

export const handleApiError = (err) => {
  const httpError = err.response;
  if (httpError) {
    const { errors } = httpError.data;
    if (errors && Object.keys(errors).length > 0) {
      const errorMesages = [];
      Object.keys(errors).forEach((key) => {
        errorMesages.push(errors[key][0]);
      });
      showGeneralError(errorMesages.join('\n'));
    } else {
      console.log(httpError);
      showGeneralError();
    }
  } else {
    console.log(httpError);
    showGeneralError();
  }
};
