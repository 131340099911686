import { useRoutes } from 'react-router-dom';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import RecoverPassword from './pages/RecoverPassword';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [{ path: '/', element: <Login /> }]
    },
    {
      path: '/recuperar-contrasena',
      element: <LogoOnlyLayout />,
      children: [{ path: '/recuperar-contrasena', element: <RecoverPassword /> }]
    }
  ]);
}
