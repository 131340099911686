import LoadingButton from '@mui/lab/LoadingButton';
// material
import { Stack, TextField } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import { useRecoverPasswordFormik } from './recover-password.formik';

// ----------------------------------------------------------------------

export default function RecoverPasswordForm() {
  const formik = useRecoverPasswordFormik();

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Recuperar
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
