import { Typography } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
//
import { MHidden } from '../components/@material-extend';
// components
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '95%',
  display: 'flex',
  alignItems: 'center',
  padding: 10,
  justifyContent: 'center',
  backgroundColor: '#27233b',
  maxWidth: 520,
  borderRadius: '16px 16px 0 0',
  height: 140,
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default function AuthLayout({ children }) {
  return (
    <HeaderStyle>
      <a href="https://sistemanube.com" target="_blank" rel="noreferrer">
        <Logo />
      </a>

      <MHidden width="smDown">
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          {children}
        </Typography>
      </MHidden>
    </HeaderStyle>
  );
}
