import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
// material
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import axios from 'axios';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { handleApiError } from '../utils';
import './login.css';
import { useLoginFormik } from './login.formik';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [clubAccounts, setClubAccounts] = useState([]);

  const navigate = useNavigate();

  const formik = useLoginFormik();

  const { errors, touched, handleSubmit: handleSubmitFormik, isSubmitting, getFieldProps } = formik;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formik.values.clubId === '') {
      const { email, password } = formik.values;
      const data = {
        email,
        password,
        appId: 'cloud-system-panel'
      };

      formik.setSubmitting(true);

      axios
        .post(`${process.env.REACT_APP_BASE_URL_API}/v1/clubs/auth/login`, data, {
          headers: {
            'x-transaction-origin': 'cloud-system-login'
          }
        })
        .then((res) => {
          formik.setSubmitting(false);
          if ('access_token' in res.data) {
            const urlPanel = process.env.REACT_APP_BASE_URL_PANEL.replace(
              '{subdomain}',
              res.data.club_subdomain
            );

            window.location.href = `${urlPanel}/login?token=${res.data.access_token}`;
          } else if (res.data.accounts.length === 1) {
            formik.setFieldValue('clubId', res.data.accounts[0].club.id);
            handleSubmitFormik(formik.values, formik);
          } else {
            setClubAccounts(res.data.accounts);
          }
        })
        .catch((err) => {
          handleApiError(err);
          formik.setSubmitting(false);
        });
    } else {
      handleSubmitFormik(formik.values, formik);
    }

    return false;
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          {clubAccounts.length > 0 && (
            <FormControl fullWidth>
              <InputLabel id="club-select-label">Club</InputLabel>
              <Select
                labelId="club-select-label"
                id="club-select"
                value={formik.values.clubId}
                label="Club"
                onChange={(ev) => {
                  formik.setFieldValue('clubId', ev.target.value);
                }}
              >
                {clubAccounts.map((account) => (
                  <MenuItem key={account.id} value={account.club.id} className="club-item">
                    <img src={account.club.logo} alt="logo" width={30} />
                    <span>{account.club.name}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Ingresar
          </LoadingButton>
          <Button
            onClick={() => {
              navigate('recuperar-contrasena');
            }}
            fullWidth
            size="large"
            variant="outlined"
          >
            Recuperar contraseña
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
